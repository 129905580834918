import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
    selector: 'app-correct',
    templateUrl: './correct.page.html',
    styleUrls: ['./correct.page.scss'],
})
export class CorrectPage implements OnInit {

    constructor(
        public modalController: ModalController,
        private navParams: NavParams
    ) {

    }

    ngOnInit() {
    }

    dismiss(action) {
        this.modalController.dismiss({
            'dismissed': true,
            'action': action,
        });
    }

}
