import { Component, ElementRef, ViewChild } from "@angular/core";
import { Platform, ToastController } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Router } from "@angular/router";
import * as uuid from 'uuid';
import { Device } from '@ionic-native/device/ngx';
import {
    Events,
    MenuController,
    NavController,
    AlertController,
} from "@ionic/angular";
import { Storage } from "@ionic/storage";
import * as firebase from "firebase";
import { NavigationExtras } from "@angular/router";
import { AllServicesService } from "./all-services.service";
import { MessagingService } from "./message/messaging.service";
import { FCM } from '@ionic-native/fcm/ngx';
import { FcmService } from "./services/fcm.service";

import { FirebaseX } from "@ionic-native/firebase-x/ngx";

@Component({
    selector: "app-root",
    templateUrl: "app.component.html",
    styleUrls: ["app.component.scss"],
})
export class AppComponent {
    title = "push-notification";
    message;
    show;

    appPages: Array<{ title: string; url: any; icon: any }>;
    logout: any;
    userDetails: any;
    is_offer: boolean = false;

    vapidKey: any = 'BCzVpQ4-3v2gmrquplGxlvPYOpanTOjtmgZGzRTmUaQ1D1ANFs09osPSuYJcPIS-pWxp5KgxSCxYyvbTRmfWITU';
    stored_deveice_id: string;
    stored_deveice_id_old_one: string;
    device_token: any;
    user: any;
    uniqueweb_id: any;
    iosToken: any;
    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private router: Router,
        private menu: MenuController,
        public navCtrl: NavController,
        public alertController: AlertController,
        public storage: Storage,
        public events: Events,
        public allServicesService: AllServicesService,
        private messagingService: MessagingService,
        private device: Device,
        private fcm: FCM,
        private fcmservice: FcmService,
        private toastController: ToastController,
        private appFirebase : FirebaseX,

    ) {
        this.storage.get("user").then((user) => {
            if (user) {
                this.user = user;
            }
        })


        this.initializeApp();

        let config = {
            apiKey: "AIzaSyB0EeZAzdF56OUC1_uD59Cnxq8omd-cOxM",
            authDomain: "green-street-law.firebaseapp.com",
            databaseURL: "https://green-street-law-default-rtdb.firebaseio.com",
            projectId: "green-street-law",
            storageBucket: "green-street-law.appspot.com",
            messagingSenderId: "979975591958",
            appId: "1:979975591958:web:60b63596198d52c7207d37"
        };
        var self = this;
        if (!firebase.apps.length) {
            firebase.initializeApp(config);
        } else {
            firebase.app(); // if already initialized, use that one
        }
        console.log("firebase messaging support browser :", firebase.messaging.isSupported());


        if (!firebase.messaging.isSupported()) return;
        const messaging = firebase.messaging()
        console.log("messaging", messaging);
        messaging.getToken(this.vapidKey).then(res => {
            console.log("res of firebase >>>>>>>>>", res);
            return messaging.getToken();
        })
            .then(deviceToken => {
                console.log("deviceToken :", deviceToken);
                this.device_token = deviceToken;

            })
            .catch(err => {
                console.log("err:", err)
            });

        if (firebase.messaging.isSupported()) {
            firebase.messaging().onMessage((payload) => {

                console.log("payload: ", payload);

                if (payload.data != undefined) {
                    if (payload.data.message != '') {
                        console.log("payload: ", payload.data);
                    }
                }
            });
        } else {
            console.log("not receiving");
        }
        console.log("myID2", this.getDeviceInfo());
    }

    ngOnInit() {
    this.appFirebase.onTokenRefresh()
            .subscribe((token: string) => console.log(`Got a new token ${token}`));
    }

    async getDeviceInfo() {
        let info = {}
        info['uuid'] = await this.device.uuid;
        return info;
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.notificationSetup();
            this.messagingService.requestPermission()
            this.messagingService.receiveMessage()
            this.show = this.messagingService.currentMessage

            this.appFirebase.grantPermission().then((res) => {
                console.log('grant success', res);
            }, (err) => {
                console.log('grant err', err);
            });

            this.appFirebase.getToken()
                .then((token) => {
                    console.log(`The token is ${token}`);
                    this.iosToken = token;
                }) // save the token server-side and use it to push notifications to this device
                .catch(error => console.error('Error getting token', error));

                // this.appFirebase.onNotificationOpen()
                this.appFirebase.onMessageReceived()
                .subscribe((data) => {
                    console.log(`User opened a notification ${data}`);
                    let data2 = JSON.stringify(data);
                    console.log('stringyfied data'+ data2);
                    if(data.tap == 'foreground' || data.tap == 'background'){
                        console.log('bookingId2',data.booking_id);
                        this.router.navigate(['/booking2/' + data.booking_id ])
                    }
                   
                })
    
            this.appFirebase.onTokenRefresh()
                .subscribe((token: string) => console.log(`Got a new token ${token}`));

            this.statusBar.styleDefault();
            this.statusBar.backgroundColorByHexString("#d1a21e");
            setTimeout(() => {
                this.splashScreen.hide();
            }, 1000);
            this.storage.get("user").then((userInfo) => {
                if (userInfo != null) {
                    console.log('userInfouserInfouserInfo: ' + userInfo);
                    this.allServicesService.firebaseAuthToken();
                    this.events.publish("userCheck:created", userInfo);
                } else {
                    this.events.publish("userCheck:created", "userNotLogin");
                    if (!this.is_offer) {
                        this.router.navigate(["/home"]);
                    }
                }
            });
        });

        this.events.subscribe("userCheck:created", (user) => {
            this.logout = true;
            if (user.token) {
                if (user.role == "barber") {
                    this.appPages = [
                        {
                            title: "Home",
                            url: "/tabs/athletehome",
                            icon: "home",
                        },
                        {
                            title: 'My Profile',
                            url: '/tabs/barberprofile/' + user.user_id + '/private',
                            icon: 'person',
                        },
                        {
                            title: "My Payment Info",
                            url: "/managebankcards",
                            icon: "card",
                        },
                        {
                            title: "My Wallet",
                            url: "/wallet",
                            icon: "card",
                        },
                        {
                            title: "About Us",
                            url: "/about",
                            icon: "person",
                        },
                        {
                            title: "Contact Us",
                            url: "/contact",
                            icon: "mail",
                        },
                        {
                            title: "Tutorial",
                            url: "/tutorial",
                            icon: "paper",
                        },
                        {
                            title: "Notifications",
                            url: "/notifications",
                            icon: "person",
                        },
                        {
                            title: "Tax Form",
                            url: "/taxform/after_login",
                            icon: "person",
                        },
                    ];
                } else {
                    this.appPages = [
                        {
                            title: "Home",
                            url: "/tabs/userhome",
                            icon: "home",
                        },
                        {
                            title: 'My Profile',
                            url: '/tabs/userprofile/' + user.user_id + '/private',
                            icon: 'person',
                        },
                        {
                            title: "My Cards",
                            url: "/managebankcards",
                            icon: "card",
                        },
                        {
                            title: "About Us",
                            url: "/about",
                            icon: "person",
                        },
                        {
                            title: "Contact Us",
                            url: "/contact",
                            icon: "mail",
                        },
                        {
                            title: "Tutorial",
                            url: "/tutorial",
                            icon: "paper",
                        },
                        {
                            title: "Notifications",
                            url: "/notifications",
                            icon: "person",
                        },
                    ];
                }
            }
            if (user == "userNotLogin") {
                this.logout = false;
                this.appPages = [
                    {
                        title: "Home",
                        url: "/tabs/shop",
                        icon: "home",
                    },
                    {
                        title: "About",
                        url: "/about",
                        icon: "person",
                    },
                    {
                        title: "Login / Signup",
                        url: "/home",
                        icon: "person",
                    }
                ];
            }
        });
    }

    async presentToast(message) {
        const toast = await this.toastController.create({
            message,
            duration: 3000
        });
        toast.present();
    }

    notificationSetup() {
        let token_new = this.fcm.getToken();
        console.log("token_new", token_new);
        this.fcm.onNotification().subscribe(
            (msg) => {
                if (this.platform.is('ios')) {
                    this.presentToast(msg.aps.alert);
                } else {
                    this.presentToast(msg.body);
                }
            });
    }

    onPushReceived(payload) {
    this.appFirebase.onTokenRefresh()
        .subscribe((token: string) => console.log(`Got a new token ${token}`));

        console.log("Push recevied:" + payload.additionalData);
        if (payload.data != undefined) {
            if (payload.data.message != '') {
                console.log("payload: ", payload.data);
                this.allServicesService.presentAlert(payload.data.title)
                return false;
            }
        }
    }

    openForm() {
        this.navCtrl.navigateForward(["/forms", { form_id: 1 }]);
    }


    sendToMessage(senderId, receiverId) {
        this.allServicesService.showLoader();
        let sendData = {
            senderId: senderId,
            receiverId: receiverId,
        };
        this.allServicesService.getChatUserInfo(sendData).subscribe(
            (result) => {
                let res: any = [];
                res = result;
                console.log("getChatUserInfo : res ", res);
                this.openChatPage(res.senderInfo, res.receiverInfo);
                this.allServicesService.dismissLoading();
            },
            (err) => {
                this.allServicesService.dismissLoading();
                console.log("error...", err);
                let msg = err.error.errormsg;
                console.log(err);
            }
        );
    }
    openChatPage(userProfile, Currentuser) {
        let userPro = {
            id: userProfile.id,
            first_name: userProfile.display_name,
            user_img: userProfile.user_img,
        };
        let navigationExtras: NavigationExtras = {
            queryParams: {
                secondUser: JSON.stringify(userPro),
                currentUser: JSON.stringify(Currentuser),
                fromMy: true,
            },
        };
        console.log(userPro, "user", Currentuser);
        this.navCtrl.navigateForward(["/chat"], navigationExtras);
    }

    async DoLogout() {
        this.menu.toggle();
        const alert = await this.alertController.create({
            header: "Logout",
            message: "Are you sure?",
            buttons: [
                {
                    text: "Cancel",
                    role: "cancel",
                    cssClass: "secondary",
                    handler: (blah) => { },
                },
                {
                    text: "Logout",
                    handler: () => {

                        this.storage.get('uniqueweb_id').then((res) => {
                            if (res) {
                                this.uniqueweb_id = res;
                            }
                        });

                        this.storage.get("user").then((user) => {
                            if (user) {
                                let body: any;
                                console.log("user", user);
                                body = {
                                    token: user.token,
                                    platform_id: this.uniqueweb_id,
                                    device_token: this.iosToken,
                                }
                                //  body = {
                                //         token: user.token,
                                //         device_token: this.iosToken,
                                //         platform: 'ios',
                                //     }
                               

                                console.log("body", body);
                                this.allServicesService.showLoader();
                                this.allServicesService.sendData("remove_device_token", body).subscribe((res) => {
                                    console.log("response======>",res)
                                    if (res['status'] == 'success' || res == null) {
                                        console.log("Hello");
                                        this.storage.remove('iosToken');
                                        this.allServicesService.dismissLoading();
                                        this.router.navigate(["/home"]);
                                        this.storage.remove("user");
                                    }
                                }, (err) => {
                                    this.allServicesService.dismissLoading();
                                    console.log("Error = ", err);
                                    if (err.error.error_code == "user_expire") {
                                        this.allServicesService.presentAlert(err.error.errormsg);
                                        this.storage.remove("user");

                                        this.router.navigate(["/login"]);
                                    }
                                    this.allServicesService.presentToast(err.error.errormsg);
                                });
                            }
                        });
                        this.events.publish("userCheck:created", "userNotLogin");
                    },
                },
            ],
        });
        await alert.present();
    }



}
