import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap, map, retry } from 'rxjs/operators';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { Globalization } from '@ionic-native/globalization/ngx';
import { Platform } from '@ionic/angular';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import * as firebase from 'firebase';

let Url = 'https://greenstreetlaw.com/';
let fullUrl = 'https://greenstreetlaw.com/wp-json/mobileapi/v1/';
let url = 'https://greenstreetlaw.com/wp-json/wp/v2/';

@Injectable({
    providedIn: 'root'
})
export class AllServicesService {
    loading: any;
    iana_timezone: any;
    one_id: any;
    token: any;
    totalPosts = null;
    pages: any;
    isPlatformAndroidIos: boolean;

    constructor(
        private http: HttpClient,
        public loadingCtrl: LoadingController,
        public alertCtrl: AlertController,
        private globalization: Globalization,
     
        public nativeStorage: NativeStorage,
        private platform: Platform,
        private toastController: ToastController
    ) {
        // const isApp: any = ((!document.URL.startsWith('http') && !document.URL.startsWith('https')) || document.URL.startsWith('http://localhost:8080'));
        // if (isApp) {
        //     this.isPlatformAndroidIos = true;
        //     console.log("isPlatformAndroidIos1=", this.isPlatformAndroidIos)
        // } else {
        //     this.isPlatformAndroidIos = false;
        //     console.log("isPlatformAndroidIos2=", this.isPlatformAndroidIos)
        // }

        const isapp:any = (document.URL.startsWith('http://app.greenstreetlaw.com')|| document.URL.startsWith('https://app.greenstreetlaw.com') || document.URL.startsWith('http://localhost:8100'));
        if(isapp){
          console.log("PWA");
          this.isPlatformAndroidIos = false;
          console.log("isPlatformAndroidIos=", this.isPlatformAndroidIos)
        }else{
          console.log("Device");
          this.isPlatformAndroidIos = true;
          console.log("isPlatformAndroidIos=", this.isPlatformAndroidIos)
        }
    }

    getURL() {
        return Url;
    }

    async presentAlert(msg) {
        let alert = await this.alertCtrl.create({
            header: 'Alert',
            message: msg,
            buttons: [{
                text: 'OK',
                handler: () => {

                }
            }]
        });

        await alert.present();
    }
    

    async presentToast(text) {
        const toast = await this.toastController.create({
            message: text,
            position: 'bottom',
            duration: 3000
        });
        toast.present();
    }

    async dismissLoading() {
        console.log(this.loading);
        await this.loading.dismiss();
    }

    async showLoader(msg: string = '') {
        if (msg == '') {
            msg = 'Please wait...';
        }
        this.loading = await this.loadingCtrl.create({ message: msg });
        await this.loading.present();
    }

    async showLoader2(msg: string = '') {
        if (msg == '') {
            msg = 'Uploading...';
        }
        this.loading = await this.loadingCtrl.create({ message: msg });
        await this.loading.present();
    }
    
    getData(endPoint) {
        return this.http.get(fullUrl + endPoint).pipe(
            map(data => {
                return data;
            })
        )
    }

    sendData(endPoint, data) {
        console.log("data" ,data);
        return this.http.post(fullUrl + endPoint, data).pipe(
            map(data => {
                return data;
            })
        )
    }

    UploadMedia(data) {
        return this.http.post(Url + 'wp-json/mobileapi/v1/uploadImage', data).pipe(
            map(data => {
                return data;
            })
        )
    }

    doLogin(endPoint, data) {
        return this.http.post(Url + 'wp-json/jwt-auth/v1/' + endPoint, data).pipe(
            map(data => {
                return data;
            })
        )
    }

    getChatUserInfo(sendData) {
        return this.http.post(Url + 'wp-json/mobileapi/v1/getChatUserInfo', sendData)
    }

    StripeAddBankAccount(token, bank_new, type: number = 0) {
        return this.http.post(Url + 'wp-json/mobileapi/v1/StripeAddBankAccount', {
            token: token,
            bank_new: bank_new,
            type: type
        });
    }

    GetCards(token) {
        return this.http.post(Url + 'wp-json/mobileapi/v1/StripeGetCards', {
            token: token,
        })
    }
    CreateStripeUser(token, stripeToken, type: number = 0) {
        return this.http.post(Url + 'wp-json/mobileapi/v1/CreateStripeUser', {
            token: token,
            stripeToken: stripeToken,
            type: type
        });
    }

    addCardtoBank(token, stripeToken) {
        return this.http.post(Url + 'wp-json/mobileapi/v1/add_debit_card', {
            token: token,
            type: 'card',
            stripeToken: stripeToken
        });
    }

    GetStripeAuthToken(token, details) {
        return this.http.post(Url + 'wp-json/mobileapi/v1/CreateStripeCaptureBooking', details).pipe(
            map(data => {
                return data;
            })
        )
    }

    saveOneSignID(token, oneSignID) {
        this.globalization.getDatePattern({ formatLength: 'string', selector: 'timezone and utc_offset' }).
            then(res => {
                this.iana_timezone = res.iana_timezone
                // this.http.post(Url + 'wp-json/mobileapi/v1/save_onesignal_id', {
                //     oneSignID: oneSignID,
                //     token: token,
                //     type: 'ios',
                //     timezone: this.iana_timezone
                // })
            })
            .catch(e => console.log(e));

        // return this.http.post(Url + 'wp-json/mobileapi/v1/save_onesignal_id', {
        //     oneSignID: oneSignID,
        //     token: token,
        //     type: 'ios',
        //     timezone: this.iana_timezone
        // });
    }

    async SaveAutoConfiqure(token) {
        console.log(token);
        if (this.platform.is('cordova')) {
            // this.oneSignal.getIds().then((id) => {
            //     this.one_id = id.userId;
            //     this.token = token;
            //     this.saveOneSignID(this.token, this.one_id).subscribe(m => {
            //     });
            // });
        }

    }

    setSetting(setting) {
        if (this.platform.is('cordova')) {
            //return this.nativeStorage.setItem('fancase_user', user);
            this.nativeStorage.setItem('setting', setting)
                .then(
                    () => console.log('Stored item!'),
                    error => console.error('Error storing item', error)
                );
        }
    }

    getStoreSetting() {
        if (this.platform.is('cordova')) {
            return this.nativeStorage.getItem('setting');
        }
    }

    GetSetting() {
        return this.http.post(Url + 'wp-json/mobileapi/v1/GetSetting', {
        });
    }

    getCurrentUserInfo(token) {
        return this.http.post(Url + 'wp-json/mobileapi/v1/getCurrentUserInfo', {
            token: token
        })
    }
    getSecoondUserInfo(token, id) {
        return this.http.post(Url + 'wp-json/mobileapi/v1/getSecoondUserInfo', {
            token: token,
            id: id
        })
    }

    getSecoondUserInfo1(token, id) {
        return this.http.post(Url + 'wp-json/mobileapi/v1/getSecoondUserInfo1', {
            token: token,
            id: id,

        })
    }

    updateCard(token, card, card_new, type) {
        return this.http.post(Url + "wp-json/mobileapi/v1/updateCard", {
            token: token,
            card: card,
            name: card_new.name,
            expMonth: card_new.expMonth,
            expYear: card_new.expYear,
            type: type,
            default: card_new.default
        }).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }

    removeCard(token, card, type) {
        return this.http.post(Url + "wp-json/mobileapi/v1/DeleteCard", {
            token: token,
            card: card,
            type: type
        }).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }

    submitForm(formId, formdata, user_id, user_token) {
        return this.http.post(fullUrl + "submitForm", {
            formId: formId,
            formdata: formdata,
            user_id: user_id,
            user_token: user_token
        })
    }

    // save_event(data, user_token) {
    //     return this.http.post(fullUrl + "save_event", {
    //         job_name: data.job_name,
    //         location: data.location,
    //         athlete_tier: data.athlete_tier,
    //         athlete_sport: data.athlete_sport,
    //         description: data.description,
    //         job_duties: data.job_duties,
    //         job_amount: data.job_amount,
    //         token: user_token
    //     })
    // }

    getPosts(page = 1, userToken: any = '', mypost: number = 0, c: number = 0): Observable<any[]> {
        let category_url = c ? ("&service_category=" + c) : "";
        let options = {
            observe: "response" as 'body',
            params: {
                per_page: '10',
                page: '' + page
            }
        };

        return this.http.get<any[]>(url + 'posts?_embed&token=' + userToken + "&mypost=" + mypost + category_url, options).pipe(
            map(resp => {
                this.pages = resp['headers'].get('x-wp-totalpages');
                this.totalPosts = resp['headers'].get('x-wp-total');

                let data = resp['body'];

                for (let post of data) {
                    post.media_url = post['media_url'];
                }
                return data;
            })
        )
    }

    getPostContent(id) {
        return this.http.get(url + 'posts/' + id + '?_embed').pipe(
            map(post => {
                post['media_url'] = post['media_url'];
                return post;
            })
        )
    }

    //--By AJP
    addReview(sendData) {
        return this.http.post(Url + 'wp-json/mobileapi/v1/add_review', sendData)
    }

    getReviews(sendData) {
        return this.http.post(Url + 'wp-json/mobileapi/v1/get_review', sendData)
    }

    editBooking(sendData) {
        return this.http.post(Url + 'wp-json/mobileapi/v1/edit_booking', sendData)
    }

    get_address(address) {
        return this.http.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + address + '&key=AIzaSyDLybXWYdyi__5WVQvO9ckf6ZRCKe6HUts').pipe(
            map(data => {
                return data;
            })

        )
    }
    make_payment(user_token, booking_id, total, card_id, customer, capture, order_type) {
        return this.http.post(Url + 'wp-json/mobileapi/v1/make_payment', {
            token: user_token,
            booking_id: booking_id,
            card_id: card_id,
            customer: customer,
            total: total,
            capture: capture,
            order_type: order_type
        });
    }

    firebaseAuthToken() {
        let email = 'mainuser@knoxweb.com';
        let password = 'sR123456';
        firebase.auth().signInWithEmailAndPassword(email, password).then((user) => {

            console.log('firebase login = ', { user });
            

        }).catch((err: any = []) => {
            console.log('err = ', { err });
            if (err.code == 'auth/user-not-found') {
                firebase.auth().createUserWithEmailAndPassword(email, password).then((user) => {
                    console.log('firebase account created = ', { user });
                    this.firebaseAuthToken();
                });
            }

        });
    }

    firebaseSignOut() {
        firebase.auth().signOut();
    }

    upload_image(data) {
        return this.http.post(Url + 'wp-json/mobileapi/v1/upload_image', data).pipe(
            map(data => {
                return data;
            })
        )
    }

}
