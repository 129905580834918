import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage';
import { AllServicesService } from '../app/all-services.service';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { PipesModule } from '../app/pipes/pipes.module'; ;
import { Device } from '@ionic-native/device/ngx';
import { Stripe } from '@ionic-native/stripe/ngx';
import { BrMaskerModule } from 'br-mask';
import { Globalization } from '@ionic-native/globalization/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Camera, CameraOptions, PictureSourceType } from '@ionic-native/Camera/ngx';
import { File, FileEntry } from '@ionic-native/File/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { GalleryCustomModalPageModule } from './gallery-custom-modal/gallery-custom-modal.module';
import { MyservicesaddPageModule } from './myservicesadd/myservicesadd.module';
import { IonicRatingModule } from 'ionic-rating';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import { InAppBrowser, InAppBrowserOptions, InAppBrowserObject } from '@ionic-native/in-app-browser/ngx';
import { HelpPageModule } from './help/help.module';
import { ResendCodePageModule } from './resend-code/resend-code.module';
import { CorrectPageModule } from './correct/correct.module';
import { IncorrectPageModule } from './incorrect/incorrect.module';
import { ReviewPageModule } from './review/review.module';
import { FilterPageModule } from './filter/filter.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { CapturePhotoPageModule } from './capture-photo/capture-photo.module';
import { SignaturePadModule } from 'angular2-signaturepad';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from'@angular/fire';
import { environment } from 'src/environments/environment';
import { MessagingService } from './message/messaging.service';
import { AsyncPipe } from '@angular/common';
import { FCM } from '@ionic-native/fcm/ngx';
import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/firestore';
// import { Firebase } from '@ionic-native/firebase/ngx';
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { FirestoreSettingsToken} from '@angular/fire/firestore';

const config = {
  apiKey: "AIzaSyB0EeZAzdF56OUC1_uD59Cnxq8omd-cOxM",
    authDomain: "green-street-law.firebaseapp.com",
    databaseURL: "https://green-street-law-default-rtdb.firebaseio.com",
    projectId: "green-street-law",
    storageBucket: "green-street-law.appspot.com",
    messagingSenderId: "979975591958",
    appId: "1:979975591958:web:60b63596198d52c7207d37"
};


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrMaskerModule,
    BrowserModule,
    PipesModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    IonicRatingModule,
    IonicModule.forRoot({
      animated:false
    }),
    AppRoutingModule,
    HttpClientModule,
    IonicStorageModule.forRoot(), 
    ReactiveFormsModule,
    GalleryCustomModalPageModule,
    MyservicesaddPageModule,
    HelpPageModule,
    ResendCodePageModule,
    CorrectPageModule,
    IncorrectPageModule,
    ReviewPageModule,
    FilterPageModule,
    SignaturePadModule,
    CapturePhotoPageModule, 
    // AngularFireModule.initializeApp(config),
    
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    StatusBar,
    SplashScreen,
    CallNumber,
    Stripe,
    Globalization,
    NativeStorage,
    Geolocation,
    Camera,
    File,
    FilePath,
    FileTransfer,
    InAppBrowser,
    MessagingService,
    AsyncPipe,
    Device,
    // IonicsignaturepadProvider,
    LaunchNavigator,
    FCM,
    // Firebase,
    FirebaseX,
    AngularFirestore,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: FirestoreSettingsToken, useValue: {} }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}


