import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';

const routes: Routes = [
    { path: '', loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule) },
    { path: 'signin',canActivate : [LoginGuard], loadChildren: './signin/signin.module#SigninPageModule' },
    { path: 'login',canActivate : [LoginGuard], loadChildren: './signin/signin.module#SigninPageModule' },
    { path: 'home',canActivate : [LoginGuard], loadChildren: './home/home.module#HomePageModule' },
    { path: 'signup',canActivate : [LoginGuard], loadChildren: './signup/signup.module#SignupPageModule' },
    { path: 'forgot',canActivate : [LoginGuard], loadChildren: './forgot/forgot.module#ForgotPageModule' },
    { path: 'shop',canActivate : [AuthGuard], loadChildren: './shop/shop.module#ShopPageModule' },
    { path: 'shop/:service_id',canActivate : [AuthGuard], loadChildren: './shop/shop.module#ShopPageModule' },
    { path: 'shop_search/:q',canActivate : [AuthGuard], loadChildren: './shop/shop.module#ShopPageModule' },
    { path: 'shop_search',canActivate : [AuthGuard], loadChildren: './shop/shop.module#ShopPageModule' },
    { path: 'barbors',canActivate : [AuthGuard], loadChildren: './barbors/barbors.module#BarborsPageModule' },
    { path: 'messages',canActivate : [AuthGuard], loadChildren: './messages/messages.module#MessagesPageModule' },
    // { path: 'askquestion', loadChildren: './askquestion/askquestion.module#AskquestionPageModule' }
    { path: 'contact',canActivate : [AuthGuard], loadChildren: './contact/contact.module#ContactPageModule' },
    { path: 'news',canActivate : [AuthGuard], loadChildren: './news/news.module#NewsPageModule' },
    { path: 'aboutshop,canActivate : [AuthGuard]', loadChildren: './aboutshop/aboutshop.module#AboutshopPageModule' },
    { path: 'userprofile,canActivate : [AuthGuard]', loadChildren: './userprofile/userprofile.module#UserprofilePageModule' },
    { path: 'userprofile/:user_id/:type', loadChildren: './userprofile/userprofile.module#UserprofilePageModule' },
    { path: 'about',canActivate : [AuthGuard], loadChildren: './about/about.module#AboutPageModule' },
    { path: 'addupload',canActivate : [AuthGuard], loadChildren: './addupload/addupload.module#AdduploadPageModule' },
    { path: 'barbercontactinfo',canActivate : [AuthGuard], loadChildren: './barbercontactinfo/barbercontactinfo.module#BarbercontactinfoPageModule' },
    { path: 'barberprofile',canActivate : [AuthGuard], loadChildren: './barberprofile/barberprofile.module#BarberprofilePageModule' },
    { path: 'barberprofile/:user_id/:type',canActivate : [AuthGuard], loadChildren: './barberprofile/barberprofile.module#BarberprofilePageModule' },
    { path: 'invoice',canActivate : [AuthGuard], loadChildren: './invoice/invoice.module#InvoicePageModule' },
    { path: 'invoice/:user_id',canActivate : [AuthGuard], loadChildren: './invoice/invoice.module#InvoicePageModule' },
    { path: 'chat',canActivate : [AuthGuard], loadChildren: './chat/chat.module#ChatPageModule' },
    { path: 'booking',canActivate : [AuthGuard], loadChildren: './booking/booking.module#BookingPageModule' },
    { path: 'booking2',canActivate : [AuthGuard], loadChildren: './booking2/booking2.module#Booking2PageModule' },
    { path: 'booking2/:booking_id',canActivate : [AuthGuard], loadChildren: './booking2/booking2.module#Booking2PageModule' },
    { path: 'nearby',canActivate : [AuthGuard], loadChildren: './nearby/nearby.module#NearbyPageModule' },
    { path: 'services',canActivate : [AuthGuard], loadChildren: './services/services.module#ServicesPageModule' },
    { path: 'searchfilter',canActivate : [AuthGuard], loadChildren: './searchfilter/searchfilter.module#SearchfilterPageModule' },
    { path: 'managebankcards',canActivate : [AuthGuard], loadChildren: './managebankcards/managebankcards.module#ManagebankcardsPageModule' },
    { path: 'choosetype',canActivate : [AuthGuard], loadChildren: './choosetype/choosetype.module#ChoosetypePageModule' },
    { path: 'billing',canActivate : [AuthGuard], loadChildren: './billing/billing.module#BillingPageModule' },
    { path: 'billing/:type',canActivate : [AuthGuard], loadChildren: './billing/billing.module#BillingPageModule' },
    { path: 'billing/:type/:page',canActivate : [AuthGuard], loadChildren: './billing/billing.module#BillingPageModule' },
    { path: 'thankyou',canActivate : [AuthGuard], loadChildren: './thankyou/thankyou.module#ThankyouPageModule' },
    { path: 'gallery-custom-modal',canActivate : [AuthGuard], loadChildren: './gallery-custom-modal/gallery-custom-modal.module#GalleryCustomModalPageModule' },
    { path: 'cardinfo',canActivate : [AuthGuard], loadChildren: './cardinfo/cardinfo.module#CardinfoPageModule' },
    { path: 'billingedit/:type/:card_id/:card_last4/:card_brand/:card_exp_month/:card_exp_year/:card_name/edit/:default',canActivate : [AuthGuard], loadChildren: './billingedit/billingedit.module#BillingeditPageModule' },
    { path: 'singlefeed/:id',canActivate : [AuthGuard], loadChildren: './singlefeed/singlefeed.module#SinglefeedPageModule' },
    { path: 'notifications',canActivate : [AuthGuard], loadChildren: './notifications/notifications.module#NotificationsPageModule' },
    { path: 'myservicesadd',canActivate : [AuthGuard], loadChildren: './myservicesadd/myservicesadd.module#MyservicesaddPageModule' },
    { path: 'wallet',canActivate : [AuthGuard], loadChildren: './wallet/wallet.module#WalletPageModule' },
    { path: 'walletrequest',canActivate : [AuthGuard], loadChildren: './walletrequest/walletrequest.module#WalletrequestPageModule' },
    //   { path: 'forgot-popup', loadChildren: './forgot-popup/forgot-popup.module#ForgotPopupPageModule' },
    { path: 'review',canActivate : [AuthGuard], loadChildren: './review/review.module#ReviewPageModule' },
    { path: 'add-review/:barber_id',canActivate : [AuthGuard], loadChildren: './add-review/add-review.module#AddReviewPageModule' },
    { path: 'edit-booking/:user_id',canActivate : [AuthGuard], loadChildren: './edit-booking/edit-booking.module#EditBookingPageModule' },
    { path: 'verification',canActivate : [AuthGuard], loadChildren: './verification/verification.module#VerificationPageModule' },
    //   { path: 'email-good', loadChildren: './email-good/email-good.module#EmailGoodPageModule' },
    { path: 'reset-password',canActivate : [AuthGuard], loadChildren: './reset-password/reset-password.module#ResetPasswordPageModule' },
    { path: 'welcome/:type',canActivate : [AuthGuard], loadChildren: './welcome/welcome.module#WelcomePageModule' },
    { path: 'archived-booking',canActivate : [AuthGuard], loadChildren: './archived-booking/archived-booking.module#ArchivedBookingPageModule' },
    { path: 'schedule',canActivate : [AuthGuard], loadChildren: './schedule/schedule.module#SchedulePageModule' },
    { path: 'schedule',canActivate : [AuthGuard], loadChildren: './schedule/schedule.module#SchedulePageModule' },
    { path: 'schedule/:booking_id',canActivate : [AuthGuard], loadChildren: './schedule/schedule.module#SchedulePageModule' },
    { path: 'add-pet/:route',canActivate : [AuthGuard], loadChildren: './add-pet/add-pet.module#AddPetPageModule' },
    { path: 'active-booking',canActivate : [AuthGuard], loadChildren: './active-booking/active-booking.module#ActiveBookingPageModule' },
    { path: 'choose-location/:route',canActivate : [AuthGuard], loadChildren: './choose-location/choose-location.module#ChooseLocationPageModule' },
    { path: 'selected-booking',canActivate : [AuthGuard], loadChildren: './selected-booking/selected-booking.module#SelectedBookingPageModule' },
    { path: 'pending-booking',canActivate : [AuthGuard], loadChildren: './pending-booking/pending-booking.module#PendingBookingPageModule' },
    { path: 'userhome',canActivate : [AuthGuard], loadChildren: './userhome/userhome.module#UserhomePageModule' },
    { path: 'bookmark',canActivate : [AuthGuard], loadChildren: './bookmark/bookmark.module#BookmarkPageModule' },
    { path: 'view-requests', loadChildren: './view-requests/view-requests.module#ViewRequestsPageModule' },
    { path: 'report-athlete',canActivate : [AuthGuard], loadChildren: './report-athlete/report-athlete.module#ReportAthletePageModule' },
    { path: 'resend-code',canActivate : [AuthGuard], loadChildren: './resend-code/resend-code.module#ResendCodePageModule' },
    { path: 'incorrect',canActivate : [AuthGuard], loadChildren: './incorrect/incorrect.module#IncorrectPageModule' },
    { path: 'correct',canActivate : [AuthGuard], loadChildren: './correct/correct.module#CorrectPageModule' },
    { path: 'help',canActivate : [AuthGuard], loadChildren: './help/help.module#HelpPageModule' },
    { path: 'tutorial',canActivate : [AuthGuard], loadChildren: './tutorial/tutorial.module#TutorialPageModule' },
    { path: 'taxform/:origin', loadChildren: './taxform/taxform.module#TaxformPageModule' },
    { path: 'taxform-reason', loadChildren: './taxform-reason/taxform-reason.module#TaxformReasonPageModule' },
    { path: 'terms', loadChildren: './terms/terms.module#TermsPageModule' },
    { path: 'policy', loadChildren: './policy/policy.module#PolicyPageModule' },
    { path: 'pending-approval', loadChildren: './pending-approval/pending-approval.module#PendingApprovalPageModule' },
    { path: 'make-payment',canActivate : [AuthGuard], loadChildren: './make-payment/make-payment.module#MakePaymentPageModule' },
    { path: 'review-listing',canActivate : [AuthGuard], loadChildren: './review-listing/review-listing.module#ReviewListingPageModule' },
    { path: 'capture-photo',canActivate : [AuthGuard], loadChildren: './capture-photo/capture-photo.module#CapturePhotoPageModule' },
    { path: 'filter',canActivate : [AuthGuard], loadChildren: () => import('./filter/filter.module').then(m => m.FilterPageModule) },  { path: 'publicprofile', loadChildren: './pages/publicprofile/publicprofile.module#PublicprofilePageModule' },

];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules , useHash: true } )
    ],
    exports: [RouterModule],
    providers: [AuthGuard]
})
export class AppRoutingModule { }
