import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import {Storage} from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate  {
  constructor(
    public storage: Storage,
    public router: Router,
    public navCtrl: NavController
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // return true;

    return new Promise((resolve) => {
      this.storage.get('user').then(val => {
        if (val != null) {
          if (val.role === 'customer') {
              this.navCtrl.navigateRoot('/tabs/userhome');
          } else {
              this.navCtrl.navigateRoot('/tabs/athletehome');
          }
          resolve(false);
        } else {
          resolve(true);
        }
      })

    });
  }
}
