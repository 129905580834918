import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
    selector: 'app-incorrect',
    templateUrl: './incorrect.page.html',
    styleUrls: ['./incorrect.page.scss'],
})
export class IncorrectPage implements OnInit {

    email: any;

    constructor(
        public modalController: ModalController,
        private navParams: NavParams
    ) {
        this.email = this.navParams.get('email');
    }

    ngOnInit() {
    }

    dismiss(action) {
        this.modalController.dismiss({
            'dismissed': true,
            'action': action,
            'email': this.email
        });
    }

}
