import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { AllServicesService } from '../all-services.service';
import { Events, AlertController, ModalController, LoadingController, NavParams, NavController, ActionSheetController, MenuController, ToastController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-capture-photo',
  templateUrl: './capture-photo.page.html',
  styleUrls: ['./capture-photo.page.scss'],
})
export class CapturePhotoPage implements OnInit {

  user: any = [];
  upload_type: any = '';
  loading: any;
  formrespnse: any;

  stream: any;
  video: any;
  canvas: any;
  is_webcam_ready: boolean = false;
  image_src: any = '';
  width: any = 320;
  height: any = 0;

  constructor(
    public events: Events,
    public allServicesService: AllServicesService,
    public loadingCtrl: LoadingController,
    public router: Router,
    public alertCtrl: AlertController,
    public modalController: ModalController,
    private navParams: NavParams,
    public storage: Storage,
    private ref: ChangeDetectorRef,
    private toastController: ToastController
  ) {
    this.storage.get('user').then(userInfo => {
      if (userInfo != null) {
        this.user = userInfo;
      } else {
        this.router.navigate(['/signin']);
      }
    }, err => {
      this.router.navigate(['/signin']);
    });

    this.upload_type = this.navParams.get('upload_type');
    console.log('this.upload_type', this.upload_type);
    if (this.upload_type) {
      this.start_stream();
    }
}

ngOnInit() {
}

async start_stream(){
  console.log('i m inside start_stream');

  this.allServicesService.showLoader();
  const constraints = {
    video: {
      width: { ideal: innerWidth },
      height: { ideal: innerHeight },
      facingMode: "environment"
    },
    audio: true
  };
  try {
    this.stream = await navigator.mediaDevices.getUserMedia(constraints);
    this.handleSuccess(this.stream);
    this.allServicesService.dismissLoading();
  } catch (e) {
    this.allServicesService.dismissLoading();
    console.error('navigator.getUserMedia error:', e);
  }
  
}

handleSuccess(stream) {
  this.allServicesService.dismissLoading();
  this.video = document.querySelector('#video');
  this.video.srcObject = stream;
  this.ref.detectChanges();
  this.is_webcam_ready = true;
  console.log("this.video.srcObject: ", this.video.srcObject);
  
}

take_photo(upload_type) {
  this.canvas = document.getElementById('canvas');
  this.height = this.video.videoHeight / (this.video.videoWidth / this.width);
  if (isNaN(this.height)) {
    this.height = this.width / (4 / 3);
  }
  console.log(this.width);
  console.log(this.height);
  this.upload_type = upload_type;
  this.is_webcam_ready = false;
  
  var photo = document.getElementById('photo');
  var context = this.canvas.getContext('2d');
  context.drawImage(this.video, 0, 0, this.width,this.height);
  var data = this.canvas.toDataURL('image/jpeg');
  this.image_src = data;

    // photo.setAttribute('src', data);
  
    this.stream.getTracks().forEach(function (track) {
      track.stop();
    });
  
  // this.uploadAll(data, 'Camera');
}

retake_picture(upload_type){
  this.upload_type = upload_type;
  this.image_src = '';
  this.start_stream();
}
// clearRecording() {
//   this.is_webcam_ready = false;
//   this.image_src = '';
//   console.log(this.video);
//   this.video.src = this.video.srcObject = null;
//   this.stream.getTracks().forEach(function (track) {
//     track.stop();
//   });
// }

async uploadAll(webPath: string, type: string) {
  console.log("type: " + type);
  this.loading = await this.loadingCtrl.create({
    message: 'Uploading...'
  });
  await this.loading.present();
  let date = new Date(),
    time = date.getTime(),
    fileName = time + ".jpg";
  const blob = '';

  const formData = new FormData();
  if (type == 'Camera') {
    const blob = await fetch(webPath).then(r => r.blob());
    formData.append('file', blob, fileName);

  } else if (type == 'Record') {
    const blob = await fetch(webPath).then(r => r.blob());
    formData.append('file', blob, time + '.webm');

  } else {
    const blob = webPath;
    formData.append('file', blob);
    console.log('formData', formData);
  }

  formData.append('upload_type', this.upload_type);
  formData.append('token', this.user.token);
  if (this.user) {
    this.allServicesService.upload_image(formData)
      .subscribe(res => {
        this.image_src = '';
        this.formrespnse = res;
        console.log(this.formrespnse);
        this.loading.dismiss();
        // if (this.formrespnse.upload_type == 'barberGallery') {
        //   this.GetUserProfileImages(this.user.token);
        // }
        // if (this.formrespnse.upload_type == 'userimage') {
        //   this.userImage = this.formrespnse.file_path;
        // }
        this.events.publish('capturePhoto:created', this.formrespnse.upload_type);

        if(this.upload_type == 'service_image'){
          this.events.publish('serviceImage:uploaded', this.formrespnse);
        }
        this.presentToast(this.formrespnse.msg);
        this.closeModal();
        this.ref.detectChanges();
      }, (err) => {
        console.log(err);
        if (err.error_code == 'user_expire') {
          this.presentToast('Please login again token expired.');
          this.storage.clear();
          this.router.navigate(['/signin']);
        }
        this.loading.dismiss();
        this.closeModal();
      });
  } else {
    this.loading.dismiss();
    this.closeModal();
    this.router.navigate(['/signin']);
  }
}

async closeModal() {
  this.is_webcam_ready = false;
  this.image_src = '';
  console.log(this.video);
  this.video.src = this.video.srcObject = null;
  this.stream.getTracks().forEach(function (track) {
    track.stop();
  });
  await this.modalController.dismiss();
}

async presentToast(text) {
  const toast = await this.toastController.create({
    message: text,
    position: 'bottom',
    duration: 3000
  });
  toast.present();
}

}
