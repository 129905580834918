import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { AllServicesService } from '../all-services.service';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';

@Component({
    selector: 'app-filter',
    templateUrl: './filter.page.html',
    styleUrls: ['./filter.page.scss'],
})
export class FilterPage implements OnInit {

    selected_sports: any = [];
    athlete_sports: any = [];
    athlete_tiers: any = [];
    selected_tier: any;
    is_loader: boolean = false;
    user: any = [];

    constructor(
        private storage: Storage,
        public modalController: ModalController,
        public popoverController: PopoverController,
        public allServicesService: AllServicesService,
        public router: Router,
        public cdrf: ChangeDetectorRef,
        public zone: NgZone,
        public cdref: ChangeDetectorRef
    ) {
        let sendData = {
            page_no: 1,
            search_keywords: '',
        }
        // this.is_skill_loader = true;
        // this.allServicesService.sendData('get_skills', sendData).subscribe((response) => {
        //   if (response['status'] == 'ok') {
        //     if (response['skills'] && response['skills'] != null) {
        //       this.skills = response['skills'];
        //       this.is_skill_loader = false;
        //       this.cdrf.detectChanges();
        //     }
        //   }
        // }, (err) => {
        //   console.log('skills err :>> ', err.error);
        //   this.is_skill_loader = false;
        // });

        this.storage.get("user").then(
            (userInfo) => {
                if (userInfo != null) {
                    this.user = userInfo;
                    this.allServicesService.SaveAutoConfiqure(this.user.token);
                    this.get_athlete_tiers();
                    // this.get_athlete_sports();
                }
            },
            (err) => { }
        );
    }

    ngOnInit() {
    }


    get_athlete_tiers() {
        this.is_loader = true;

        this.allServicesService
            .getData("get_athlete_tiers/?token=" + this.user.token)
            .subscribe(
                (data) => {
                    let res: any = [];

                    res = data;
                    this.is_loader = false;
                    if (res.status === "ok") {
                        this.athlete_tiers = res.athlete_tiers;
                    }
                },
                (err) => {
                    this.is_loader = false;
                    if (err.error.error_code == "user_expire") {
                        this.allServicesService.presentAlert(err.error.errormsg);
                        // this.storage.clear();
                        this.router.navigate(["/login"]);
                    }
                    this.allServicesService.presentAlert(err.error.errormsg);
                }
            );
    }

    get_athlete_sports() {
        this.is_loader = true;

        this.allServicesService
            .getData("get_athlete_sports/?token=" + this.user.token)
            .subscribe(
                (data) => {
                    let res: any = [];

                    res = data;
                    this.is_loader = false;
                    if (res.status === "ok") {
                        this.athlete_sports = res.athlete_sports;
                    }
                },
                (err) => {
                    this.is_loader = false;
                    if (err.error.error_code == "user_expire") {
                        this.allServicesService.presentAlert(err.error.errormsg);
                        // this.storage.clear();
                        this.router.navigate(["/login"]);
                    }
                    this.allServicesService.presentAlert(err.error.errormsg);
                }
            );
    }


    dismiss(action) {
        console.log('action :>> ', action);
        if (action == 'ok') {

            this.modalController.dismiss({
                'action': action,
                'selected_tier': this.selected_tier
            });

        } else if (action == 'clear') {

            this.selected_sports = [];

            this.modalController.dismiss({
                'action': action,
                'selected_tier': ''
            });
        } else {
            this.modalController.dismiss({
                'action': action,
            });
        }
    }

}
