import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
// import { Events, Platform, ToastController, ModalController, NavController, NavParams, MenuController, LoadingController, AlertController } from '@ionic/angular';

@Component({
    selector: 'app-resend-code',
    templateUrl: './resend-code.page.html',
    styleUrls: ['./resend-code.page.scss'],
})
export class ResendCodePage implements OnInit {

    action: any;

    constructor(
        public modalController: ModalController,
        private navParams: NavParams
    ) {
        this.action = this.navParams.get('action');
    }

    ngOnInit() {
    }

    dismiss(action) {
        this.modalController.dismiss({
            'dismissed': true,
            'action': action
        });
    }

    resend_code(action){
        this.modalController.dismiss({
            'dismissed': true,
            'action': action
        });
    }
}
